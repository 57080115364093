














































































































































































.card {
  &-title {
    font-size: x-large;
    text-transform: uppercase;
  }

  &-subtitle {
    font-size: large;
  }

  &-col {
    color: black;
    padding: 0px 10px;
  }

  &-divider {
    margin: 10px 0px;
    background-color: black;
  }

  &-spacer {
    margin: 10px 0px;
  }

  &-negative {
    color: red;
  }
}
